import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lng]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lng]/layout.tsx\",\"import\":\"Lexend\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-lexend\"}],\"variableName\":\"lexend\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/flag-icons/css/flag-icons.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default","src"] */ "/opt/build/repo/public/favicon.ico");
;
import(/* webpackMode: "eager", webpackExports: ["CookiesBanner"] */ "/opt/build/repo/src/components/CookiesBanner.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/tailwind.css");
