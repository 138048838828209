'use client'

import React from 'react'
import { useTranslation } from '@/app/i18n/client'
import { CommonLangProps } from '@/types/page'
import { Button } from '@/components/Button'
import Link from 'next/link'
import { getLocalStorage, setLocalStorage } from '@/utils/storage'

type CookiesBannerProps = CommonLangProps

const COOKIE_CONSENT_LS_KEY = 'cookie_consent'
const COOKIE_CONSENT_GRANTED_VALUE = 'granted'
const COOKIE_CONSENT_DENIED_VALUE = 'denied'

const privacyAndTermsReady = false

export const CookiesBanner = (props: CookiesBannerProps) => {
  const { lng } = props

  const [cookieConsent, setCookieConsent] = React.useState<null | boolean>(null)
  const [isLoadedFromStorage, setIsLoadedFromStorage] =
    React.useState<boolean>(false)
  const userAlreadyAnswered = cookieConsent !== null

  const { t } = useTranslation(lng, 'cookies')

  const handleAcceptCookies = () => {
    setCookieConsent(true)
  }

  const handleRejectCookies = () => {
    setCookieConsent(false)
  }

  React.useEffect(() => {
    const storedCookieConsent = getLocalStorage(COOKIE_CONSENT_LS_KEY, null)

    if (storedCookieConsent !== null) {
      setCookieConsent(storedCookieConsent)
    }

    setIsLoadedFromStorage(true)
  }, [setCookieConsent])

  React.useEffect(() => {
    if (userAlreadyAnswered) {
      const newValue = cookieConsent
        ? COOKIE_CONSENT_GRANTED_VALUE
        : COOKIE_CONSENT_DENIED_VALUE

      window.gtag('consent', 'update', {
        analytics_storage: newValue,
      })

      setLocalStorage(COOKIE_CONSENT_LS_KEY, cookieConsent)
    }
  }, [userAlreadyAnswered, cookieConsent])

  return (
    <div
      className={`fixed bottom-0 z-20 flex w-full flex-col gap-6 border-t-2 border-brand-rose-400 bg-brand-rose-50 px-3 py-6 lg:flex-row lg:flex-nowrap ${!isLoadedFromStorage || userAlreadyAnswered ? 'hidden' : 'flex'}`}
    >
      <div className="flex flex-1 flex-col gap-6 px-3 lg:border-r-2">
        <h4 className="font-bold text-brand-stone-950">
          {t('cookies.heading')}
        </h4>
        <p className="text-brand-stone-950">{t('cookies.description')}</p>
        {privacyAndTermsReady ? (
          <p>
            {t('cookies.moreInfo_p1')}
            <Link href="/polityka-prywatnosci" className="underline">
              {` ${t('cookies.moreInfo_p2')}`}
            </Link>
            {` ${t('cookies.moreInfo_p3')} `}
            <Link href="/regulamin" className="underline">
              {t('cookies.moreInfo_p4')}.
            </Link>
          </p>
        ) : null}
      </div>
      <div className="flex flex-1 flex-col gap-6 px-3 lg:border-r-2">
        <h4 className="font-bold text-brand-stone-950">
          {t('privacy.heading')}
        </h4>
        <p>{t('privacy.description')}</p>
      </div>
      <div className="flex flex-1 flex-row items-center justify-center gap-6 px-3 lg:justify-end">
        <Button variant="solid" color="brand" onClick={handleAcceptCookies}>
          {t('buttons.accept')}
        </Button>
        <Button
          variant="outline"
          color="brand"
          href="/"
          onClick={handleRejectCookies}
        >
          {t('buttons.reject')}
        </Button>
      </div>
    </div>
  )
}
